import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import MainContainer from "../components/main-container"
import styled from "styled-components"

const ButtermonkeyImg = styled.img`
  display: block;
  /* margin: 0 auto; */
  width: 30vw;
  min-width: 200px;
  max-width: 30%;
  margin-bottom: -1em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const IntroParagraph = styled.div`
  display: block;
  margin: 1.5em;
  margin-left: 0;
  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.h2};
`

const NewsletterSignupButton = styled.a`
  display: block;
  margin: 1em;
  margin-left: 0;
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.p};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const SocialText = styled.p`
  display: block;
  margin: 1em;
  margin-left: 0;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.p};
`

const SocialButton = styled.a`
  display: inline-block;
`

export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ghostbutter - Contact</title>
        <link rel="canonical" href="https://ghostbutter.com/contact/" />
      </Helmet>
      <Header></Header>
      <MainContainer>
        <ButtermonkeyImg src="/ghostbutter-buttermonkey-black.png" />
        <IntroParagraph>There are many ways to get in touch:</IntroParagraph>
        <NewsletterSignupButton
          href="https://ghostbutter.us16.list-manage.com/subscribe?u=48b4aefe953875e3252060cef&id=9bf52c43d3"
          target="_blank"
        >
          • Why not sign up for our <b>Newsletter</b>? Don't worry, we won't
          spam!
        </NewsletterSignupButton>
        <SocialText>
          • Follow us on{" "}
          <SocialButton href="https://twitter.com/ghostbutter">
            <b>Twitter</b>
          </SocialButton>{" "}
          or{" "}
          <SocialButton href="https://instagram.com/ghostbutter">
            <b>Instagram</b>
          </SocialButton>
        </SocialText>
        <SocialText>
          <SocialButton href="mailto:mail@ghostbutter.com">
            • Write us a mail, the <i>old-fashioned way</i>:
            mail@ghostbutter.com
          </SocialButton>
        </SocialText>
        <SocialText>
          <SocialButton href="https://discord.gg/XJkzHyQ">
            • If you want to <i>hang out & chat</i>, head on over to our{" "}
            <b>Discord!</b>
          </SocialButton>
        </SocialText>
      </MainContainer>
      <Footer></Footer>
    </Layout>
  )
}
